import React, { useState, useEffect } from 'react';
import { postWhatballerAttempt } from './http/whatballer.http';

const wordSpaceCharacter = ' ';

function InputCharacters({ 
  inputCharacters, 
  handleObfuscation,
  inputRefs, 
  visibleIndexes, 
  setInputCharacters,
  handleCorrectAnswer,
  setError,
  date,
  difficulty,
  setAttempts,
  setFirstLetterUsed,
  // isCorrect,
  // attempts,
}) {
  const [isColourChanging, setIsColourChanging] = useState(true);

  useEffect(() => {
    // Remove the colour change after 1 second
    const timer = setTimeout(() => {
      setIsColourChanging(false);
    }, 1000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const handleInputChange = (index, value) =>{
    if (value === wordSpaceCharacter) { 
      return;
    }

    if (inputCharacters.every(char => char.correct === true)) {
      return;
    }

    // Ensure only one character per input box
    if (value.length > 1) {
      return;
    }
    
    const newInputs = [...inputCharacters];
    newInputs[index].value = value.toUpperCase(); // Convert input to uppercase for uniformity
    setInputCharacters(newInputs, index);
    
    if (index === inputCharacters.length - 1) {
      return handleSubmit();
    }

    const indexForFocus = handleLookBackRecursion(inputCharacters, index, true)

    if (indexForFocus === -1) {
      handleSubmit();
      return;
    }

    inputRefs.current[indexForFocus].focus();
  };

  const shouldSkipInput = (inputToCheck) => {
    return inputToCheck.value === " " || inputToCheck.correct === true
  }

  const handleLookBackRecursion = (inputCharacters, index, forward) => {
    if (forward) {
      if (index === inputCharacters.length - 1) {
        return -1;
      }

      const inputToCheck = inputCharacters[index + 1];

      if (shouldSkipInput(inputToCheck)) {
        return handleLookBackRecursion(inputCharacters, index + 1, true);
      }

      return index + 1;
    }

    /**
     * Deleting starts here
     */
    if (index === 0) {
      return -1;
    }

    const previousInput = index - 1;

    const inputToCheck = inputCharacters[previousInput];

    // https://www.premierleague.com/news/3960097#:~:text=Unsurprisingly%2C%20England%20tops%20the%20list,Premier%20League%20club%20since%201992.
    if (shouldSkipInput(inputToCheck)) {
      return handleLookBackRecursion(inputCharacters, previousInput);
    }

    return previousInput;
  }

  const handleKeyDown = (index, event) => {
    if (inputCharacters.every(char => char.correct === true)) {
      return;
    }

    if (event.key === "Backspace" && index > 0) {
      const newInputs = [...inputCharacters];

      const getIndexForFocus = handleLookBackRecursion(inputCharacters, index, false);

      if (getIndexForFocus === -1) {
        return;
      }

      // If the user got that char wrong reset it
      if (newInputs[getIndexForFocus].correct !== true) {
        newInputs[getIndexForFocus].value = '_';
      }

      setInputCharacters(newInputs)

      inputRefs.current[getIndexForFocus].focus();
    }

    if (event.key === "ArrowLeft" && index > 0) {
      const getIndexForFocus = handleLookBackRecursion(inputCharacters, index, false);

      if (getIndexForFocus === -1) {
        return;
      }

      inputRefs.current[getIndexForFocus].focus();
    }

    if (event.key === "ArrowRight" && index > 0) {
      const getIndexForFocus = handleLookBackRecursion(inputCharacters, index, true);

      if (getIndexForFocus === -1) {
        return;
      }

      inputRefs.current[getIndexForFocus].focus();
    }
  };

  const handleSubmit = (e) => {
    postWhatballerAttempt({ difficulty, date, name: inputCharacters })
      .then(({ data: whatballerAttempt }) => {
        if (whatballerAttempt.results) {
          const obfuscatedAttempt = handleObfuscation(whatballerAttempt.results[0].attempt)
          setInputCharacters(obfuscatedAttempt);
          setAttempts(whatballerAttempt.results);
          setFirstLetterUsed(whatballerAttempt.results.some(result => result.attempt.some(a => a.hint === true)));
        } else if (whatballerAttempt.code === 'MAXIMUM_ATTEMPTS_REACHED') {
          // DO somethings
        }

        const indexToFocus = 
          whatballerAttempt.results[0].attempt.findIndex(char => 
            char.correct === false &&
            char.value !== wordSpaceCharacter
          );

        if (indexToFocus >= 0) {
          inputRefs.current[indexToFocus].focus();
        }

        if (whatballerAttempt.correctAttempt) {
          handleCorrectAnswer()
        }
      })
      .catch((err) => {
        setError(err.message); // Handle error if any
        console.error("Error fetching data:", err);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-wrap justify-center mt-0">
      {inputCharacters.map((char, index) => (
        char.value !== wordSpaceCharacter ? (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={char.value === '_' ? '' : char.value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={(el) => (inputRefs.current[index] = el)} // Attach the ref to each input
            className={`
              input-fade transition-opacity duration-500 ${
                visibleIndexes.includes(index) ? 'opacity-100' : 'opacity-0'
              } 
              w-10
              h-10
              mx-0.5
              sm:mx-1
              text-center 
              text-lg 
              text-slate-100
              ${typeof char === 'object' &&
                  (char?.correct === true || char.reveal) ? 
                    `transition-colors duration-100 ${
                      // isCorrect && attempts?.length ?
                      //   isColourChanging ? 'bg-yellow-500' : 'bg-yellow-500 text-slate-800' :
                      char.reveal ?
                        isColourChanging ? 'bg-red-800' : 'bg-red-900' :
                      char.hint ?
                        isColourChanging ? 'bg-blue-700' : 'bg-blue-800' :
                        isColourChanging ? 'bg-green-700' : 'bg-green-800'
                }` : 
                'bg-slate-600'}
              border-gray-400 
            border-r-emerald-50 
              border-b-2 
              focus:outline-none 
              focus:border-gold-500
            `}
          />
        ) : (
        <div key={index} className="w-full h-2 sm:w-10 sm:h-10"></div>
        )
      ))
      }
    </form>
  );
}

export default InputCharacters;
