import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import SplashPage from './Splash';
import PlayPage from './Play'; // This will be the page you navigate to when "Play" is clicked.
// import History from './history'; // Assuming this is the History page component

// Add a 'give up' button
// Should alter the colour of the input box slightly when a character is inputted
// Make shareable button after you get it

// https://github.com/rubengmurray/whatballer/pull/10/files
// https://chatgpt.com/share/67026206-81e0-8001-9783-65fa3cdb3971
// https://chatgpt.com/share/67026211-1140-8001-82e3-750d49cdcec1

export const GOOGLE_SSO_CLIENT_ID = '400677473536-v9d94vjps09ch7tiq81c84hk0h3jsv2j.apps.googleusercontent.com';

const App = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_SSO_CLIENT_ID}>
    <Router>
      <Analytics />
      <Routes>
        {/* SplashPage is the default route */}
        {/* <Route path="/" element={<SplashPage />} /> */}
        <Route path="/" element={<PlayPage />} />
        <Route path="*" element={<PlayPage />} />

        {/* The Play page */}
        {/* <Route path="/play" element={<PlayPage />} /> */}
        {/* <Route path="/history" element={<History />} /> */}
      </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
