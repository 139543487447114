import React, { useState } from 'react';
import dayjs from './dependencies/dayjs';
// import DropdownMenu from './DropdownMenu';
// import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import GoogleLoginComponent from './GoogleLogin';
import { GoShare } from 'react-icons/go';
// import { MdOutlineIosShare } from "react-icons/md";

function Header({ isCorrect, isLoggedIn, setIsLoggedIn, getWhatballerT, handleShare }) {
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  return (
    <header className="w-full 2xl:w-6/12">
      <div className="h-10 flex items-center justify-between px-2 w-full bg-slate-100">
        <Link to="/">
          <img className="cursor-pointer" src="./logo-uncropped.svg" />
        </Link>

        <div className="absolute left-1/2 transform -translate-x-1/2">
          <p className="font-panton font-bold text-center">
            {dayjs().format('Do MMM YYYY')}
          </p>
        </div>
            
        {isCorrect && <div className="ml-auto flex items-center">
          <div
            className="
              cursor-pointer
              text-slate-900
              rounded-md
              mr-4
              text-xl
            "
            onClick={handleShare}
          >
            <GoShare />
          </div>
        </div>
        }
          <GoogleLoginComponent
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            getWhatballerT={getWhatballerT}
          />
        </div>
      {/* <div className="relative">
          <FaBars
            className="h-6 w-6 text-gray-700 cursor-pointer"
            onClick={toggleDropdown}
          />

          {isDropdownOpen && <DropdownMenu />}
        </div> */}
      <h1 className="text-center text-5xl font-bold font-maytra text-gray-100 m-0 mt-8 p-0">
        WHATBALLER
      </h1>
    </header>
  );
}

export default Header;
