import axios from 'axios';

// export const backendUrl = 'http://127.0.0.1:3500';
export const backendUrl = 'https://site--whatballer-be--cgbkzhc2qrj7.code.run';

export const getHeaders = (token) => {
  return {
    token: token || localStorage.getItem('whatballer:auth'),
    Accept: 'application/json',
  }
}

const backendHTTP = axios.create({
  baseURL: backendUrl,
  timeout: 10000,
  // headers: getHeaders(),
  validateStatus: (status) => {
    if (status === 401) {
      return true;
    }

    if (status < 400) {
      return true;
    }

    return false;
  }
});

export const getWhatballer = (args) => {
  return backendHTTP.get('/whatballer',
    {
      params: {
        userId: localStorage.getItem('whatballer-uuid'), 
        ...args,
      },
      headers: getHeaders(),
    },    
  )
}

export const getWhatballerAttempt = (args) => {
  const { difficulty, date } = args;

  return backendHTTP.get('/whatballer/attempt',
    {
      params: {
        userId: localStorage.getItem('whatballer-uuid'), 
        difficulty,
        date,
      },
      headers: getHeaders(),
  },
  )
}

export const postWhatballerAttempt = (args) => {
  const { difficulty, date, name } = args;

  return backendHTTP.post('/whatballer/attempt', { 
    name, 
    userId: localStorage.getItem('whatballer-uuid'), 
    difficulty,
    date,
  }, {
      headers: getHeaders(),
    })
}

export const getWhatballerHint = (args) => {
  const { type, date, difficulty } = args;

  return backendHTTP.get('/whatballer/hint', {
    params: { 
      type,
      date,
      difficulty,
    },
    headers: getHeaders(),
  })
}
